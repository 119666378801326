import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import logoSvg from '../assets/img/logo.svg';
import logo2 from '../assets/img/logo2.svg';
import logo2Hover from '../assets/img/logo2_hover.svg';
import logo3 from '../assets/img/logo3.svg';
import logo3Hover from '../assets/img/logo3_hover.svg';
import icon5 from '../assets/img/icon5.svg';
import certik from '../assets/img/certik.png';
import { useTheme, useMediaQuery } from '@mui/material';
import CookieConsent from "react-cookie-consent";

const FooterWrap = styled.div`
  padding: ${(props) => (props.matches ? '26px 0 49px' : '73px 0 31px')};
  .logo{
    margin: 0 auto 44px;
    img{
      width: 162.778px;
      height: 23.237px;
    }
  }
  .link{
    display: flex;
    justify-content: center;
    align-items: center;
    a{
      display: block;
      width: 32px;
      height: 32px;
    }
    a: nth-of-type(1) {
      margin-right: 19px;
      background: url(${logo2}) 100% 100% no-repeat;
      &:hover {
        background: url(${logo2Hover}) 100% 100% no-repeat;
      }
    }
    a: nth-of-type(2) {
      background: url(${logo3}) 100% 100% no-repeat;
      &:hover {
        background: url(${logo3Hover}) 100% 100% no-repeat;
      }
    }
  }
  .links{
    display: ${(props) => (props.matches ? 'block' : 'flex')};
    justify-content: center;
    align-items: center;
    margin: 44px auto;
    gap: 64px;
    color: rgba(255, 255, 255, 0.50);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    a{
      margin-bottom: ${(props) => (props.matches ? '16px' : '0')};
      display: block;
    }
  }
  .desc{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    margin-bottom: 44px;
    color: rgba(255, 255, 255, 0.50);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
    letter-spacing: 0.2px;
    img{
      width: 24px;
      height: 24px;
    }
  }
  .hr{
    margin: 0 auto;
    width: ${(props) => (props.matches ? '300px' : '1170px')};
    height: 1px;
    background-color: rgba(255, 255, 255, 0.10);
  }
  .copyRight{
    margin: 44px auto 0;
    color: rgba(255, 255, 255, 0.50);
    text-align: center;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
`;

const Footer = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: '#17172C',
        borderRadius: '32px 32px 0px 0px',
        background: 'linear-gradient(220deg, rgba(1, 36, 143, 0.24) 8.52%, rgba(1, 36, 143, 0.00) 82.41%)',
        color: 'white',
        width: '100%',
      }}
    >

      <FooterWrap matches={matches}>
        <div className="logo">
          <img src={logoSvg} alt="logo" />
        </div>
        <div className="link">
            <a href='https://x.com/Rolldex_io' target='_blank' rel="noreferrer"></a>
            <a href='https://discord.com/invite/rolldex' target='_blank' rel="noreferrer"></a>
        </div>
        <div className="links">
          <a href='https://rolldex.gitbook.io/rolldex' target='_blank' rel="noreferrer">Terms and Conditions</a>
          <a href='https://rolldex.gitbook.io/rolldex' target='_blank' rel="noreferrer">Docs</a>
          <a href='https://app.rolldex.io/referrals' target='_blank' rel="noreferrer">Referral</a>
          <a href='https://rolldex.gitbook.io/rolldex' target='_blank' rel="noreferrer">Media Kit</a>
          <a href='https://discord.com/invite/rolldex' target='_blank' rel="noreferrer">Help Center</a>
        </div>
        <div className="desc">
            <span>Audited by</span>
            <img src={icon5} alt="logo" />
            <span><a href="https://skynet.certik.com/projects/rolldex" target='_blank'>Certik</a></span>
        </div>
        <div className="hr"></div>
        <div className="copyRight">
          <div>Copyright © 2024-2025 RollDex</div>
          <div>All Rights Reserved.</div>
        </div>
      </FooterWrap>
      <CookieConsent enableDeclineButton
        buttonText="Accept All"
        declineButtonText="Decline"
        style={{ background: "#413373cf" }}
      >This website uses cookies. We use cookies to make sure you get the best experience on our website.</CookieConsent>
    </Box>
  );
};

export default Footer;
