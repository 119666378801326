import React from 'react';
import styled from '@emotion/styled';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";
import { numberWithCommas } from "../../../../utils/numbers";

const SlideItem = styled.div`  
    box-sizing: border-box;
    display: flex;
    padding: ${(props) => (props.matches ? '12px' : '20px 28px')};
    width: ${(props) => (props.matches ? '159px' : '278px')};  
    height: ${(props) => (props.matches ? '53px' : '86px')};   
    border-radius: ${(props) => (props.matches ? '8px' : '16px')};
    border: 1.5px solid rgba(255, 255, 255, 0.05);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(153, 153, 153, 0.07) 40.11%);
    img{
        width: ${(props) => (props.matches ? '30px' : '46px')};
        height: ${(props) => (props.matches ? '30px' : '46px')};
        border-radius: 50%;
    }
    .right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: ${(props) => (props.matches ? '10px' : '18px')};
        height: ${(props) => (props.matches ? '28px' : '44px')};
        text-align: left;
        .name{
            color: rgba(255, 255, 255, 0.50);
            font-family: Lato;
            font-size: ${(props) => (props.matches ? '9px' : '12px')};
            line-height: ${(props) => (props.matches ? '9px' : '12px')};
            font-style: normal;
            font-weight: 300;
        }
        .num{
            color: #FFF;
            font-size: ${(props) => (props.matches ? '14px' : '20px')};
            line-height: ${(props) => (props.matches ? '14px' : '20px')};
        }
        .percent{
            display: ${(props) => (props.matches ? 'none' : '')};
            margin: ${(props) => (props.matches ? '2px 0 0 8px' : '2px 0 0 8px')};
            padding: ${(props) => (props.matches ? '0 4px' : '0 12px')};
            text-align: center;
            font-size: ${(props) => (props.matches ? '12px' : '14px')};
            line-height: 140%;
            border-radius: 4px;
        }
        .up{
            color: #B8E2D2;
            background: rgba(58, 107, 96, 0.80);
        }
        .down{
            color: #EF5A56;
            background: rgba(92, 58, 66, 0.80);
        }
    }
`;

function HorizontalScroll({list = [], matches}) {
    
    return (
        <div  style = {{width: "100%"}}>
            <div className="">
                <Splide
                    options={{
                        type: "loop",  
                        autoScroll: {
                            pauseOnHover: true,  
                            rewind: true,
                            speed: .7
                        },
                        arrows: false,
                        pagination: false,
                        fixedWidth: `${ matches ? '167px' : '308px' }`,  
                        gap: `${ matches ? '0' : '15px' }`,
                    }}
                    extensions={{ AutoScroll }} // 使用 AutoScroll 扩展  
                >   {list.map((item) => {
                    return (
                        <SplideSlide key={item.symbol}>
                            <SlideItem matches={matches}>
                                <img src={item.img} alt=''/>
                                <div className='right'>
                                    <div className='name'>{item.symbol.slice(0, -4)}</div>
                                    <div className='num'>${numberWithCommas(item.lastPrice)}
                                    {item.priceChangePercent && <span className={`percent ${item.priceChangePercent.startsWith('-') ? 'down' : 'up'}`}>{item.priceChangePercent.startsWith('-') ? '' : '+'}{item.priceChangePercent}%</span>}
                                    </div>
                                </div>
                            </SlideItem>
                        </SplideSlide>
                    );
                })}
                </Splide>
            </div>
        </div>
    );
}

export default HorizontalScroll;