import { useState, useEffect } from 'react';
import axios from "axios";
import eth from '../../assets/img/ic_eth_40.svg'
import sol from '../../assets/img/sol.png'
import ai16z from '../../assets/img/ai16z.png';
import btc from '../../assets/img/ic_btc.b_40.svg';
import shib from '../../assets/img/1000shib.svg';
import pepe from '../../assets/img/1000pepe.svg';
import dog from '../../assets/img/dog.png';
import xrp from '../../assets/img/xrp.png';
import trump from '../../assets/img/trump.png';
import Icon1 from '../../assets/img/icon1.svg';
import Icon2 from '../../assets/img/icon2.svg';
import Icon3 from '../../assets/img/icon3.svg';
import Icon4 from '../../assets/img/icon4.svg';
import Icon5 from '../../assets/img/icon5.png';
import Icon1Hover from '../../assets/img/icon1_hover.svg';
import Icon2Hover from '../../assets/img/icon2_hover.svg';
import Icon3Hover from '../../assets/img/icon3_hover.svg';
import chatImg from '../../assets/img/chat_img.png';
import vectorIcon from '../../assets/img/vector.svg';
import stakeBg from '../../assets/img/stake_bg.png';
import descBg from '../../assets/img/desc_bg.png';
import descBgHover from '../../assets/img/desc_bg_hover.png';
import styled from '@emotion/styled';
import { useTheme, useMediaQuery } from '@mui/material';
import '@dotlottie/react-player/dist/index.css';
import HorizontalScroll from './components/HorizontalScroll'
import { getTradeSummary } from '../../api'
import { numberWithCommas } from "../../utils/numbers";

const Main = styled.div`
  margin: ${(props) => (props.matches ? '0 20px' : '0 auto')};
  max-width: 1170px;
`;


const HomeWrap = styled.div`
  padding-top: ${(props) => (props.matches ? '8px' : '20px')}
`;

const BaseBtn = styled.div`
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.10);
    font-weight: bold;
    &:hover{
      color: #200A3D;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.10);
      border: 1px solid rgba(255, 255, 255, 0.80);
      background: linear-gradient(115deg, #6DEAAC 6.48%, #51B1E6 86.01%);
      box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    a{
      display: block;
    }
    a:hover{
      color: #200A3D;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.10);
    }
`

const LinkBtn = styled(BaseBtn)`
    width: ${(props) => (props.matches ? '74px' : '120px')};
    height: ${(props) => (props.matches ? '20px' : '32px')};
    line-height: ${(props) => (props.matches ? '20px' : '32px')};
    font-size: ${(props) => (props.matches ? '10px' : '16px')};
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.10);
    a{
      background: linear-gradient(101deg, #6DEAAC 48.67%, #51B1E6 94.52%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    a:hover{
      -webkit-text-fill-color: currentColor;
    }
`
const LaunchBtn = styled.div`
    display: ${(props) => (props.matches && props.smallNone ? 'none' : 'block')};
    width: ${(props) => (props.matches ? '74px' : '140px')};
    height: ${(props) => (props.matches ? '20px' : '38px')};
    line-height: ${(props) => (props.matches ? '20px' : '38px')};
    font-size: ${(props) => (props.matches ? '10px' : '16px')};
    font-family: Lato;
    font-weight: bold;
    color: #200A3D;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.10);
    background: linear-gradient(115deg, #6DEAAC 6.48%, #51B1E6 86.01%);
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 38px;
    a{
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 23px;
      width: 100%;
      height: 100%;
      font-family: Lato;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.10);
      span{
        font-weight: bold !important;
      }
    }
    img{
      margin-top: 3px;
      width: 10px;
      height: 10px;
    }
`
const TitleWrap = styled.h1`
  margin-bottom: ${(props) => (props.matches ? '15px' : '0')};
  text-align: center;
  color: #FFF;
  text-shadow: 0px 0px 83.52px #5A45FE, 0px 0px 48.72px #5A45FE, 0px 0px 24.36px #5A45FE, 0px 0px 6.96px #5A45FE, 0px 0px 3.48px #5A45FE;
  font-family: Lato;
  font-size: ${(props) => (props.matches ? '24px' : '75px')};
  font-style: normal;
  line-height: ${(props) => (props.matches ? '24px' : '120px')}; 
  font-weight: ${(props) => (props.matches ? '900' : '700')};
  white-space: nowrap;
  text-transform: capitalize;
`
const SubTitleWrap = styled.div`
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 3.48px #5A45FE;
  font-family: Lato;
  font-size: ${(props) => (props.matches ? '14px' : '32px')};
  font-weight: 300 !important;
  line-height: ${(props) => (props.matches ? '24px' : '42px')}; 
  letter-spacing: ${(props) => (props.matches ? '0.14px' : '0.24px')};
`;

const TradeWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    gap: ${(props) => (props.matches ? '4px' : '32px')};
    margin: ${(props) => (props.matches ? '34px 0 32px' : '66px auto 137px')};
    .item{
      box-sizing: border-box;
      padding: ${(props) => (props.matches ? '12px 12px 16px' : '20px 32px 17px')};
      width: ${(props) => (props.matches ? '49.2%' : '350px')};
      border-radius: ${(props) => (props.matches ? '8px' : '16px')};
      border: 1px solid rgba(255, 255, 255, 0.10);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(153, 153, 153, 0.07) 40.11%);
      backdrop-filter: blur(25px);
    }
    .item:hover{
      border: 1px solid rgba(255, 255, 255, 0.40);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(153, 153, 153, 0.09) 40.11%);
    }
    .top{
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      .head{
        width: ${(props) => (props.matches ? '27px' : '40px')};
        height: ${(props) => (props.matches ? '27px' : '40px')};
        border-radius: 66px;
        border: 1px solid rgba(235, 217, 254, 0.40);
        background: rgba(217, 217, 217, 0.13);
      }
    }
    .md{
      margin: ${(props) => (props.matches ? '4px 0 4px 0' : '14px 0 2px 0')};
      font-size: ${(props) => (props.matches ? '10px' : '20px')};
      line-height: normal;
      color: #fff;
      font-weight: 300;
      text-align: left;
    }
    .bottom{
      display: flex;
      justify-content: ${(props) => (props.matches ? 'space-between' : 'flex-start')};
      align-items: center;
      color: #FFF;
      font-size: ${(props) => (props.matches ? '14px' : '28px')};
      font-weight: 400;
      text-align: left;
      line-height: normal;
      .percent{
        margin: ${(props) => (props.matches ? '2px 0 0 8px' : '2px 0 0 8px')};
        padding: ${(props) => (props.matches ? '0 4.5px' : '0 12px')};
        text-align: center;
        font-family: Lato;
        font-size: ${(props) => (props.matches ? '14px' : '20px')};
        line-height: 140%;
        font-weight: 400;
        border-radius: 4.262px;
      }
      .up{
        color: #B8E2D2;
        background: rgba(58, 107, 96, 0.80);
      }
      .down{
        color: #EF5A56;
        background: rgba(92, 58, 66, 0.80);
      }
    }
`
const TotalData = styled.div`
    display: ${(props) => (props.matches ? 'block' : 'flex')};
    flex-wrap: nowrap;
    justify-content: center;
    align-item: center;
    gap: ${(props) => (props.matches ? '0' : '100px')};
    margin-bottom: ${(props) => (props.matches ? '60px' : '93px')};
    .item{
      margin-bottom: ${(props) => (props.matches ? '24px' : '0')};
      .num{
        height: ${(props) => (props.matches ? '28px' : '58px')};
        color:#fff;
        text-shadow: 0px 6px 5px rgba(0, 0, 0, 0.25);
        font-family: Lato;
        font-size: ${(props) => (props.matches ? '24px' : '48px')};
        line-height: ${(props) => (props.matches ? '28px' : '58px')};
        font-style: normal;
        font-weight: 700;
      }
      .desc{
        color: #F9F2FF;
        font-family: Lato;
        font-size: ${(props) => (props.matches ? '14px' : '24px')};
        font-weight: 400;
      }
    }
`

const DescWrap = styled.div`
    display: ${(props) => (props.matches ? 'block' : 'flex')};
    flex-wrap: nowrap;
    justify-content: space-between;
    align-item: center;
    margin-top: ${(props) => (props.matches ? '47px' : '87px')};
    max-width: 1170px;
    .item{
      box-sizing: border-box;
      margin-bottom: ${(props) => (props.matches ? '16px' : '0')};
      padding: ${(props) => (props.matches ? '23px 23px 26px' : '35px 37px 33px 40px')}; 
      width: ${(props) => (props.matches ? '100%' : '365px')};
      border-radius: ${(props) => (props.matches ? '10px' : '20px')};
      background-image: url(${descBg});
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      &:hover{
        background-image: url(${descBgHover});
      }
      .icon{
        display: block;
        width: ${(props) => (props.matches ? '36px' : '50px')};
        height: ${(props) => (props.matches ? '36px' : '50px')};
        background-size: 100% 100%;
      }
      .title{
        margin: ${(props) => (props.matches ? '12px 0 6px' : '17px 0 9px')};
        text-align: left;
        color: #F9F2FF;
        font-size: ${(props) => (props.matches ? '16px' : '24px')};
        font-style: normal;
        font-weight: 400;
      }
      .desc{
        margin-bottom: ${(props) => (props.matches ? '7px' : '15px')};
        text-align: left;
        color: rgba(255, 255, 255, 0.60);
        font-family: Lato;
        font-size: ${(props) => (props.matches ? '12px' : '16px')};
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }
    .item1{
      &:hover{
        .icon{
          background-image: url(${Icon1Hover});
        }
      }
      .icon{
        background-image: url(${Icon1});
      }
    }
    .item2{
      &:hover{
        .icon{
          background-image: url(${Icon2Hover});
        }
      }
      .icon{
        background-image: url(${Icon2});
      }
    }
    .item3{
      &:hover{
        .icon{
          background-image: url(${Icon3Hover});
        }
      }
      .icon{
        background-image: url(${Icon3});
      }
    }
`

const ChatWrap = styled.div`
    margin: ${(props) => (props.matches ? '107px 0 39px' : '300px 0 168px')};
    img{
      display: block;
      width: 100%;
    }
`

const BitWrap = styled.div`
  display: ${(props) => (props.matches ? 'block' : 'flex')};
  flex-wrap: nowrap;
  justify-content: space-between;
  align-item: center;
  padding: ${(props) => (props.matches ? '21px 15px' : '63px 38px 60px 48px')};
  border-radius: ${(props) => (props.matches ? '10px' : '20px')};
  background: linear-gradient(0deg, rgba(27, 19, 43, 0.60) 0%, rgba(27, 19, 43, 0.60) 100%), linear-gradient(179deg, rgba(255, 255, 255, 0.03) 0.54%, rgba(255, 255, 255, 0.00) 30.68%), linear-gradient(88deg, rgba(119, 232, 182, 0.03) 0.45%, rgba(55, 131, 255, 0.08) 29.26%, rgba(16, 60, 255, 0.09) 61.05%, rgba(0, 9, 110, 0.12) 97.96%);
  backdrop-filter: blur(2px);
  .left{
    width: ${(props) => (props.matches ? '251px' : '489px')};
    .title{
      color: #F9F2FF;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 20px 30px rgba(0, 0, 0, 0.35);
      font-family: Lato;
      font-size: ${(props) => (props.matches ? '24px' : '40px')};
      font-style: normal;
      font-weight: 900;
      line-height: ${(props) => (props.matches ? '34px' : '58px')}; 
      text-align: left;
      letter-spacing: 1.2px;
      text-transform: capitalize;
    }
    .desc{
      margin: ${(props) => (props.matches ? '8px 0 18px' : '13px 0 62px')};
      color: rgba(255, 255, 255, 0.80);
      text-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
      font-family: Lato;
      font-size: ${(props) => (props.matches ? '14px' : '24px')};
      font-style: normal;
      text-align: left;
      font-weight: 300;
      line-height: ${(props) => (props.matches ? '20px' : '28px')}; 
      letter-spacing: 0.24px;
    }
  }
  .right{
    .wrap{
      &:nth-of-type(1){
        margin-bottom: ${(props) => (props.matches ? '8px' : '35px')};
      }
      display: flex;
      justify-content: space-between;
      align-item: center;
      box-sizing: border-box;
      padding: ${(props) => (props.matches ? '0 22px 0 24px' : '0 40px 0 43px')};
      width: ${(props) => (props.matches ? '100%' : '544px')};
      height: ${(props) => (props.matches ? '76px' : '136px')};
      color: #F9F2FF;
      font-size: ${(props) => (props.matches ? '14px' : '24px')};
      line-height: ${(props) => (props.matches ? '76px' : '136px')};
      border-radius: ${(props) => (props.matches ? '10px' : '20px')};
      border: 1px solid rgba(255, 255, 255, 0.10);
      background: rgba(16, 18, 25, 0.60);
      &:hover{
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: 120% 200%;
        background-position: center;
        background-image: url(${descBgHover});
      }
      img{
        margin-top: ${(props) => (props.matches ? '18px' : '33px')};
        width: ${(props) => (props.matches ? '39px' : '69px')};
        height: ${(props) => (props.matches ? '39px' : '69px')};
      }
    }
  }
`

const StakeWrap = styled.div`
  margin-bottom: ${(props) => (props.matches ? '46px' : '350px')};
  padding-top: ${(props) => (props.matches ? '115px' : '240px')};
  background-image: url(${stakeBg});
  background-size: ${(props) => (props.matches ? '191px 187px' : '400px 390px')};
  background-position: center top;
  background-repeat: no-repeat;
  .title{
    color: #F9F2FF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 20px 30px rgba(0, 0, 0, 0.35);
    font-family: Lato;
    font-size: ${(props) => (props.matches ? '24px' : '40px')};
    font-style: normal;
    line-height: ${(props) => (props.matches ? '34px' : '58px')}; /* 145% */
    letter-spacing: 1.2px;
    text-transform: capitalize;
    .title1{
      font-weight: 900;
    }
    .title2{
      margin: ${(props) => (props.matches ? '5px 0 23px' : '24px 0 0')};
      font-size: ${(props) => (props.matches ? '14px' : '24px')};
      font-weight: 300;
      line-height: ${(props) => (props.matches ? '24px' : '28px')}; /* 116.667% */
      letter-spacing: 0.24px;
    }
  }
  .box{
    display: ${(props) => (props.matches ? 'block' : 'flex')};
    justify-content: space-between;
    margin-top: ${(props) => (props.matches ? '0' : '80px')};
    .item{
      &:nth-of-type(1){
        margin-bottom: ${(props) => (props.matches ? '25px' : '0')};
      }
      box-sizing: border-box;
      padding: ${(props) => (props.matches ? '8px' : '13px 17px')};
      width: ${(props) => (props.matches ? '100%' : '560px')};
      text-align: left;
      border-radius: ${(props) => (props.matches ? '10px' : '20px')};
      border: 1px solid rgba(119, 232, 182, 0.20);   
      .wrap{
        padding: ${(props) => (props.matches ? '16px 16px 20px' : '30px 40px 38px')};
        border-radius: ${(props) => (props.matches ? '10px' : '20px')};
        background: linear-gradient(0deg, rgba(27, 19, 43, 0.60) 0%, rgba(27, 19, 43, 0.60) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(55, 131, 255, 0.07) 40.11%);
        backdrop-filter: blur(2px);   
        &:hover{
          cursor: pointer;
          background-repeat: no-repeat;
          background-size: 120% 150%;
          background-position: center;
          background-image: url(${descBgHover});
        }
        .icon{
          display: block;
          width: ${(props) => (props.matches ? '36px' : '65px')};
          height: ${(props) => (props.matches ? '36px' : '65px')};
        }
        .name{
          margin: ${(props) => (props.matches ? '8px 0 8px' : '16px 0 8px')};
          color: #F9F2FF;
          font-size: ${(props) => (props.matches ? '16px' : '28px')};
          line-height: normal;
          font-style: normal;
          font-weight: 400;       
          text-align: left;
        }
        .desc{
          height: ${(props) => (props.matches ? 'unset' : '72px')};
          color: rgba(255, 255, 255, 0.60);
          font-size: ${(props) => (props.matches ? '12px' : '20px')};
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
        .btn{
          margin-top: ${(props) => (props.matches ? '12px' : '18px')};
          padding: ${(props) => (props.matches ? '0 15px' : '0 21px')};
          display: inline-block;
          height: ${(props) => (props.matches ? '20px' : '36px')};
          border-radius: 30px;
          background: rgba(255, 255, 255, 0.10);
          color: #FFF;
          font-size: ${(props) => (props.matches ? '9px' : '16px')};
          line-height: ${(props) => (props.matches ? '20px' : '36px')};
          text-align: center;
          font-style: normal;
          font-weight: 300;
          .btn-num{
            background: var(--Linear, linear-gradient(115deg, #6DEAAC 6.48%, #51B1E6 86.01%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: ${(props) => (props.matches ? '11px' : '20px')};
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }
  }
`
const Home = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [list, setList] = useState([
    { img: btc, symbol: 'BTCUSDT', lastPrice: '', priceChangePercent: '' },
    { img: eth, symbol: 'ETHUSDT', lastPrice: '', priceChangePercent: '' },
    { img: sol, symbol: 'SOLUSDT', lastPrice: '', priceChangePercent: '' },
    { img: shib, symbol: '1000SHIBUSDT', lastPrice: '', priceChangePercent: '' },
    { img: pepe, symbol: '1000PEPEUSDT', lastPrice: '', priceChangePercent: '' },
    { img: ai16z, symbol: 'AI16ZUSDT', lastPrice: '', priceChangePercent: '' },
    { img: dog, symbol: 'DOGEUSDT', lastPrice: '', priceChangePercent: '' },
    { img: xrp, symbol: 'XRPUSDT', lastPrice: '', priceChangePercent: '' },
    { img: trump, symbol: 'TRUMPUSDT', lastPrice: '', priceChangePercent: '' },
  ]);

  const [totalData, setTotalData] = useState({
    totalTrades: '',
    totalVolume: '',
  })

  useEffect(() => {
    getList()
    getTotalAmount()
    // const timer = setInterval(() => {
    //   getList()
    // }, 5000)
    // return () => {
    //   clearInterval(timer)
    // }
  }, [])

  function getList() {
    axios.get('https://www.binance.com/fapi/v1/ticker/24hr')
      .then((response) => {
        let newList = [];
        response.data.forEach((dataItem) => {
          const  { symbol, lastPrice, priceChangePercent } = dataItem;
          newList = list.map((item) => {
            if (item.symbol === symbol) {
              item.lastPrice = parseFloat(lastPrice).toFixed(4).replace(/(\.\d*?)0+$/, '$1')
              item.priceChangePercent = parseFloat(priceChangePercent).toFixed(2)
            }
            return item
          });
        });
        setList(newList)
      })
      .catch((error) => { });
  }

  function getTotalAmount() {
    getTradeSummary().then(res => {
      if((res || []).length > 0){
        setTotalData({
          totalTrades: parseInt(res[0].totalTrades),
          totalVolume: parseInt(res[0].totalVolume)
        })
      }
    })
  }

  return (
    <HomeWrap className="home" matches={matches}>
      <TitleWrap matches={matches}>No.1 BTC Native {matches ? <br /> : ''}Perpetual Exchange</TitleWrap>
      <Main matches={matches} >
        <SubTitleWrap matches={matches}>
          <span>Trade BTC & Memes with up to 500x Leverage</span>
          <br />
          <span>Earn BTC from Trading Fees Directly</span>
        </SubTitleWrap>
        <TradeWrap matches={matches}>
          {list.slice(0, 6).map((item, index) => {
            return (
              <div key={index} className="item" matches={matches}>
                <div className='top'>
                  <img className='head' src={item.img} width={matches ? 40 : 56} alt={item.name} />
                  <LinkBtn matches={matches}>
                    <a href="https://app.rolldex.io" target='_blank' rel="noreferrer">Trade Now</a>
                  </LinkBtn>
                </div>
                <div className="md">{item.symbol.slice(0, -4)}</div>
                <div className='bottom'>
                  <span>${numberWithCommas(item.lastPrice)}</span>
                  {item.lastPrice && <span className={`percent ${item.priceChangePercent.startsWith('-') ? 'down' : 'up'}`}>{item.priceChangePercent.startsWith('-') ? '' : '+'}{item.priceChangePercent}%</span>}
                </div>
              </div>
            );
          })}
        </TradeWrap>

        <TotalData matches={matches}>
          <div className="item">
            <div className='num'>$1,805,598</div>
            <div className='desc'>TVL</div>
          </div>
          <div className="item">
            <div className='num'>${numberWithCommas(totalData.totalVolume)}</div>
            <div className='desc'>Cumulative Volume</div>
          </div>
          <div className="item">
            <div className='num'>{numberWithCommas(totalData.totalTrades)}</div>
            <div className='desc'>Total Trades</div>
          </div>
        </TotalData>
      </Main>

      {
        list[0].lastPrice && <HorizontalScroll list={list} matches={matches}></HorizontalScroll>
      }

      <Main matches={matches}>
        <DescWrap matches={matches}>
          <div className='item item1'>
            <div className='icon icon1'></div>
            <div className='title'>Ultra-High Leverage</div>
            <div className='desc'>Maximize Your Gains with 500x Leverage on BTC! Trade like a pro with the highest leverage in the BTC ecosystem.</div>
            <LinkBtn matches={matches}>
              <a href="https://app.rolldex.io" target='_blank' rel="noreferrer">
                Trade Now
              </a>
            </LinkBtn>
          </div>
          <div className='item item2'>
            <div className='icon icon2'></div>
            <div className='title'>BTC-Native Asset Support</div>
            <div className='desc'>Use Your BTC to Earn More BTC! Stake BTC & LST for collateral and liquidity – maximize your yield.</div>
            <LinkBtn matches={matches}>
              <a href="https://app.rolldex.io" target='_blank' rel="noreferrer">
                Trade Now
              </a>
            </LinkBtn>
          </div>
          <div className='item item3'>
            <div className='icon icon3'></div>
            <div className='title'>Real Yield Generation</div>
            <div className='desc'>Earn Real BTC Rewards from Every Trade! Get paid in BTC with innovative staking & CeDeFi strategies.</div>
            <LinkBtn matches={matches}>
              <a href="https://app.rolldex.io" target='_blank' rel="noreferrer">
                Trade Now
              </a>
            </LinkBtn>
          </div>
        </DescWrap>

        <ChatWrap matches={matches}>
          <img src={chatImg} alt="chatImg" />
        </ChatWrap>

        <BitWrap matches={matches}>
          <div className='left'>
            <p className='title'>Available on</p>
            <p className='title'>the First BitVM L2</p>
            <div className='desc'>Bridge BTC, trade with up to 500x leverage, and earn BTC directly.</div>
            <LaunchBtn matches={matches} smallNone={true}>
              <a href="https://app.rolldex.io" target='_blank' rel="noreferrer">
                <span>Trade Now</span>
                <img src={vectorIcon} alt="vectorIcon" />
              </a>
            </LaunchBtn>
          </div>
          <div className='right'>
            <div className='wrap' onClick={() => window.open("https://www.bitlayer.org/bridge", "_blank")}>
              <span>Bridge & Trade</span>
              <img src={Icon5} alt="" />
            </div>

            <div className='wrap' onClick={() => window.open("https://www.btrscan.com/address/0x3d0E678776e4287BEfB0449d344D195ad1A2C418", "_blank")}>
              <span>Bitlayer Scan</span>
              <img src={Icon5} alt="" />
            </div>
          </div>
        </BitWrap>

        <StakeWrap matches={matches}>
          <div className='title'>
            <h1 className='title1'>Stake, Earn, And Multiply Your BTC</h1>
            <div className='title2'>Passive income from RollDex trading fees & yield vaults</div>
          </div>
          <div className='box'>
            <div className='item'>
              <div className='wrap' onClick={() => window.location.href = "https://app.rolldex.io/pools"}>
                <img className='icon' src={Icon4} alt="" />
                <div className='name'>RollDex RLP</div>
                <div className='desc'>- Earn 30% of leverage trading fees <br/>- Provide liquidity, earn BTC rewards</div>
                <div className='btn'>
                  <span>Current APR: </span>
                  <span className="btn-num">15.6%</span>
                </div>
              </div>
            </div>
            <div className='item'>
              <div className='wrap'>
                <img className='icon' src={Icon2} alt="" />
                <div className='name'>BTC Prime Vault</div>
                <div className='desc'>- The highest-yield BTC staking vault in DeFi <br/>- Earn BTC from top-tier CeDeFi trading strategies <br/>- Designed for BTC-maxis & high-yield seekers</div>
                <div className='btn'>Coming Soon</div>
              </div>
            </div>
          </div>
        </StakeWrap>
      </Main>
    </HomeWrap>
  );
};

export default Home;
